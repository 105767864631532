<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      responsive="sm"
      show-empty
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      @row-clicked="rowClicked"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(time_in)="data">
        <span class="font-weight-bold" v-if="data.item.attendance">
          <span
            class="menu-item bg-light-danger rounded px-2 py-2 text-danger"
            v-if="data.item.attendance.late"
            v-b-tooltip.hover="'Late'"
          >
            {{ data.item.attendance.time_in }}
          </span>
          <span class="" v-if="!data.item.attendance.late">
            {{ data.item.attendance.time_in }}
          </span>
        </span>
      </template>

      <template v-slot:cell(time_out)="data">
        <span class="font-weight-bolder" v-if="data.item.attendance">
          {{ data.item.attendance.time_out }}
        </span>
      </template>

      <template v-slot:cell(temperature)="data">
        <span
          class="font-weight-bolder"
          v-bind:class="{
            'text-success': data.item.attendance.temperature.is_safe,
            'text-danger': !data.item.attendance.temperature.is_safe,
          }"
          v-if="data.item.attendance && data.item.attendance.temperature"
        >
          {{ data.item.attendance.temperature.temp + "\xB0C" }}
        </span>
      </template>

      <template v-slot:cell(status)="data">
        <span class="font-weight-bolder">
          {{ data.item.att_status !== null ? data.item.att_status.name : "" }}
        </span>
      </template>

      <template v-slot:cell(actions)="data">
        <EmployeeDailyAttendanceActions
          :data="data"
          @expandDetails="showEmployeeDetails"
          @showManageModal="showManageModal"
          @showUpdateModal="showUpdateModal"
          :manageable="manageable"
        ></EmployeeDailyAttendanceActions>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="Empty Attendance"
          text-bottom="Empty Attendance"
        ></AppEmptyList>
      </template>

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>

  </div>
</template>

<script>
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import EmployeeDailyAttendanceActions from "@/modules/company/components/shared/attendance/daily/EmployeeDailyAttendanceActions";
import i18n from "@/core/plugins/vue-i18n";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "EmployeeDailyAttendanceTable",
  components: {
    AppLoader,
    CommonEmployeeSummary,
    EmployeeDailyAttendanceActions,
    AppEmptyList,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    manageable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "status", label: "Status" },
        { key: "time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
    };
  },
  methods: {
    /**
     * Handle on row click
     *
     * @param data
     */
    rowClicked(data) {
      this.$emit("expandDetails", {
        item: data,
      });
    },

    /**
     *
     * @param row
     */
    showEmployeeDetails(row) {
      console.log("Emit employee details clicked");
      this.$emit("expandDetails", row);
    },

    /**
     *
     * @param row
     */
    showManageModal(row) {
      console.log("Emit Show Manage Modal");
      this.$emit("showManageModal", row);
    },

    /**
     *
     * @param row
     */
    showUpdateModal(row) {
      console.log("Emit Show Manage Modal");
      this.$emit("showUpdateModal", row);
    },
  },
};
</script>

<style scoped></style>
