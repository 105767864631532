<template>
  <!-- begin: flex box -->
  <div>
    <EmployeeCountCard
      @card-clicked="goToEmployee"
      class="mr-4 mb-5 mb-lg-0"
    ></EmployeeCountCard>
  </div>
  <!-- end: flex box -->
</template>

<script>
import EmployeeCountCard from "@/modules/dashboard/components/employee/EmployeeCountCard";
export default {
  name: "EmployeeCountDashboard",
  components: { EmployeeCountCard },
  methods: {
    /**
     * Go to Employee
     */
    goToEmployee() {
      this.$router.push({ name: "employeeList" });
    },
  },
};
</script>

<style scoped></style>
