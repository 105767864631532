<template>
  <div>
    <!--begin::Dashboard-->
    <div class="d-flex flex-wrap flex-column">
      <b-card class="flex-grow-1 bg-primary text-white">
        <h2>Welcome back, {{ profile.name }}</h2>
        <h5>
          Is in <strong>{{ profile.organisation }}</strong>
        </h5>
      </b-card>

      <div v-if="isAdmin">
        <h6 class="my-5 text-black-50">Attendance</h6>
        <div class="d-flex flex-column flex-md-row">
          <EmployeeCountDashboard class="mt-1"></EmployeeCountDashboard>
          <EmployeeAttendanceCountDashboard
            class="mt-1"
          ></EmployeeAttendanceCountDashboard>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import EmployeeCountDashboard from "@/modules/dashboard/components/employee/EmployeeCountDashboard";
import EmployeeAttendanceCountDashboard from "@/modules/dashboard/components/attendance/EmployeeAttendanceCountDashboard";

export default {
  name: "dashboard",
  components: { EmployeeAttendanceCountDashboard, EmployeeCountDashboard },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.DASHBOARD") },
    ]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
    ...mapGetters({
      isAdmin: "getAdminPermission",
    }),
  },
};
</script>
