<template>
  <div>
    <div v-if="!isBusy" class="d-flex flex-column flex-md-row">
      <EmployeeAttendanceCountCard
        title="Present"
        :count="counts.presentCount"
        @card-clicked="goToPresent"
        class="mr-6 mb-5 mb-lg-0"
      ></EmployeeAttendanceCountCard>

      <EmployeeAttendanceCountCard
        title="Absent"
        :count="counts.absentCount"
        @card-clicked="goToAbsent"
        class="mr-6 mb-5 mb-lg-0"
      ></EmployeeAttendanceCountCard>

      <EmployeeAttendanceCountCard
        title="Leave"
        :count="counts.leaveCount"
        @card-clicked="goToLeave"
        class="mr-6 mb-5 mb-lg-0"
      ></EmployeeAttendanceCountCard>

      <EmployeeAttendanceCountCard
        title="Away"
        :count="counts.awayCount"
        @card-clicked="goToAway"
        class="mr-6"
      ></EmployeeAttendanceCountCard>
    </div>
    <AppLoader v-else></AppLoader>
    <EmployeeAttendanceDashboardModal
      :title="title"
      :att-status-id="id"
    ></EmployeeAttendanceDashboardModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import EmployeeAttendanceCountCard from "@/modules/dashboard/components/attendance/EmployeeAttendanceCountCard";
import EmployeeAttendanceDashboardModal from "@/modules/dashboard/components/attendance/EmployeeAttendanceDashboardModal";

export default {
  name: "EmployeeAttendanceCountDashboard",
  components: {
    EmployeeAttendanceDashboardModal,
    EmployeeAttendanceCountCard,
    AppLoader,
  },
  data() {
    return {
      title: "Title",
      id: 0,
      isBusy: false,
    };
  },
  mounted() {
    this.isBusy = true;
    this.$store
      .dispatch("fetchEmployeeDashboardDailyAttendanceCount")
      .finally(() => (this.isBusy = false));
  },
  methods: {
    fetch() {
      this.$store.commit("setEmployeeDashboardDailyAttendancePage", 1);
      this.$store.dispatch("fetchEmployeeDashboardDailyAttendanceList", {
        id: this.id,
      });
    },
    goToPresent() {
      this.title = "Employee Present Daily Attendance List";
      this.id = 1;
      this.fetch();

      this.$bvModal.show("common-employee-details-modal");
    },

    goToAbsent() {
      this.title = "Employee Absent Daily Attendance List";
      this.id = 2;
      this.fetch();

      this.$bvModal.show("common-employee-details-modal");
    },

    goToLeave() {
      this.title = "Employee Leave Daily Attendance List";
      this.id = 3;
      this.fetch();

      this.$bvModal.show("common-employee-details-modal");
    },

    goToAway() {
      this.title = "Employee Away Daily Attendance List";
      this.id = 4;
      this.fetch();

      this.$bvModal.show("common-employee-details-modal");
    },
  },
  computed: {
    ...mapGetters({
      counts: "getEmployeeDashboardDailyAttendanceCount",
    }),
  },
};
</script>

<style scoped></style>
