<template>
  <CommonEmployeeDetailsModal :title="title">
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusy"
      :pagination="pagination"
      :manageable="false"
      @changePage="handlePageChange"
      @expandDetails="handleExpand"
    ></EmployeeDailyAttendanceTable>

    <EmployeePageClockRecordModal
      v-bind:attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>
  </CommonEmployeeDetailsModal>
</template>

<script>
import { mapGetters } from "vuex";
import CommonEmployeeDetailsModal from "@/modules/company/components/shared/employee/modal/CommonEmployeeDetailsModal";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeDailyAttendanceTable from "@/modules/company/components/shared/attendance/daily/EmployeeDailyAttendanceTable";

export default {
  name: "EmployeeAttendanceDashboardModal",
  components: {
    EmployeeDailyAttendanceTable,
    EmployeePageClockRecordModal,
    CommonEmployeeDetailsModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    attStatusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      attendanceId: null,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeDashboardDailyAttendanceList", {
          id: this.attStatusId,
        })
        .finally(() => (this.isBusy = false));
    },
    handlePageChange(page) {
      this.$store.commit("setEmployeeDashboardDailyAttendancePage", page);
      this.fetch();
    },

    handleExpand(data) {
      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Employee has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }
      this.attendanceId = data.item.attendance ? data.item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeDashboardDailyAttendanceList",
      pagination: "getEmployeeDashboardDailyAttendancePagination",
      isBusy: "getEmployeeDashboardDailyAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
