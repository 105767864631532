<template>
  <b-card class="card-custom" v-on:click="$emit('card-clicked')">
    <div class="d-flex">
      <span class="svg-icon svg-icon-4x svg-icon-primary">
        <inline-svg src="/media/svg/icons/Home/Clock.svg" />
      </span>

      <div class="d-flex flex-column">
        <h1 class="ml-5 font-weight-bolder text-dark">
          {{ count }}
        </h1>
        <span class="ml-5 text-muted">
          {{ title }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "EmployeeAttendanceCountCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
